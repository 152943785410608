import React from 'react';


function Favourites() {
    return (
        <main role="main">
            <div className="container">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/404.png`} alt="Upload" />
            </div>

        </main>
    );
}

export default Favourites;
